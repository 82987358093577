<template>
  <div>
    <section class="section">
      <b-loading
        v-model="loading"
        :is-full-page="false"
        class="is-primary"
      >
      </b-loading>
      <div
        v-if="!loading && !success"
        class="container"
      >
        <div class="content">
          <h1>{{ $t('revision.title') }}</h1>
          <h5>{{ $t('revision.commentFromCorpoExpress') }}</h5>
          <b-message type="is-blue">
            <p class="is-pre-wrap">{{ getAgentMessage }}</p>
          </b-message>
        </div>
      </div>
      <div
        v-else-if="!loading && success"
        class="container"
      >
        <div class="content mt-6 has-text-centered">
          <b-icon
            pack="far"
            icon="check-circle"
            class="fa-7x"
            type="is-success"
          >
          </b-icon>
          <h1 class="is-size-2">{{ $t('revision.confirmation.title') }}</h1>
          <p class="is-size-5">{{ $t('revision.confirmation.linkInfo') }}</p>
          <b-button
            tag="router-link"
            :to="{ name: 'otherservices' }"
            type="is-primary"
            size="is-medium"
          >
            {{ $t('revision.confirmation.otherServices') }}
          </b-button>
        </div>
      </div>
    </section>
    <section
      v-if="!loading && !success"
      class="section has-background-white-ter"
    >
      <div class="container">
        <component
          v-for="(step, stepIndex) in revisionSteps"
          :key="stepIndex"
          :id="`page-${step.component}`"
          :ref="`page-${step.component}`"
          :is="`page-${step.component}`"
          class="page-confirmation__review"
        >
        </component>
        <b-field :label="$t('revision.clientMessage')">
          <b-input
            v-model="message"
            maxlength="1000"
            type="textarea"
            autocomplete="off"
          >
          </b-input>
        </b-field>
        <div class="has-text-centered">
          <b-button
            class="revision-submit"
            type="is-blue"
            @click="submit"
          >
            {{ $t('revision.submit') }}
          </b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import api from '@/api';

export default {
  name: 'Revision',
  components: {
    PageCompanyName: () => import('@/components/page/PageCompanyName'),
    PageDirector: () => import('@/components/page/PageDirector'),
    PageFounder: () => import('@/components/page/PageFounder'),
    PageHeadOffice: () => import('@/components/page/PageHeadOffice'),
    PageOfficer: () => import('@/components/page/PageOfficer'),
    PageOptions: () => import('@/components/page/PageOptions'),
    PageOther: () => import('@/components/page/PageOther'),
    PageProfessionalOrder: () => import('@/components/page/PageProfessionalOrder'),
    PageShare: () => import('@/components/page/PageShare'),
    PageShareholder: () => import('@/components/page/PageShareholder')
  },
  data () {
    return {
      message: '',
      loading: true,
      success: false
    };
  },
  mounted () {
    this.fetchRevision(this.revisionId)
      .then(() => {
        this.loading = false;
        this.$i18n.locale = this.revisionLocale;
      })
      .catch(() => {
        this.$router.push('/');
      });
  },
  computed: {
    ...mapGetters('revision', [
      'getAgentMessage',
      'getRevisedValues'
    ]),
    ...mapState('revision', [
      'revisionLocale'
    ]),
    ...mapState('form', [
      'currentForm',
      'model',
      'steps'
    ]),
    revisionId () {
      return this.$route.params.revisionId;
    },
    revisionSteps () {
      if (this.currentForm) {
        return this.steps.slice(0, -2);
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions('revision', [
      'fetchRevision',
      'clearRevision'
    ]),
    submit () {
      const validators = this.revisionSteps.map((step) => this.$refs[`page-${step.component}`][0].validator());
      const updators = this.revisionSteps.map((step) => this.$refs[`page-${step.component}`][0].updateStoredModel());

      Promise.all(validators)
        .then(() => {
          Promise.all(updators)
            .then(() => this.submitRevision());
        })
        .catch((pageName) => {
          if (pageName) {
            const pageId = pageName.split(/(?=[A-Z])/).join('-').toLowerCase();
            document.getElementById(pageId)?.scrollIntoView({ behavior: 'smooth' });
          }
        });
    },
    submitRevision () {
      this.loading = true;
      api.submitRevision(this.revisionId, this.message, this.getRevisedValues)
        .then(() => {
          this.success = true;
          this.clearRevision();
          window.scrollTo(0, 0);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
